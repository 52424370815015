import TextareaAutosize from "react-textarea-autosize";
import { useTranslation } from "react-i18next";
import { Disclosure } from "@headlessui/react";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useDebouncedValue } from "@mantine/hooks";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import endpoints from "../../../api/endpoints";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function FormPanel({
  systemPrompt,
  setSystemPrompt,
  prompts,
  product,
  valid,
  setProduct,
  runGPT,
  isAILoading,
  limit,
  user,
  setPremiumLockModalOpen,
  LoadingIcon,
}) {
  const { t } = useTranslation();

  const scrollToRef = useRef(null);

  const axiosPrivate = useAxiosPrivate();

  // useDebouncedValue
  const [debouncedValue, setDebouncedValue] = useDebouncedValue(
    product.businessInfo,
    500
  );

  const [subcat, setSubcat] = useState("All");

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const setGPTModel = (model) => {
    if (!user.isPremium) {
      setPremiumLockModalOpen(true);
      return;
    }
    setProduct({ ...product, model });
  };

  const getSubcatItems = () => {
    return prompts;
  };

  const loadDefaultBusinessInfo = () => {
    let defaultBusinessInfo = `
商戶名稱: Articue
Instagram: @articue.io
簡介: 我們是一個專業的文案生成工具，幫助你快速生成各種文案
產品: 文案生成AI
目標客戶: 有需要快速產生文案的人，小編，小商戶
特色: 專為中文而設、快速、簡單、多種模板選擇、無需VPN`;

    defaultBusinessInfo = defaultBusinessInfo.trim();

    setProduct({ ...product, businessInfo: defaultBusinessInfo });
  };

  const scrollToSelectedTemplate = () => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const updateBusinessInfo = async () => {
    try {
      await axiosPrivate.patch(
        endpoints.PROFILE_URL,
        {
          businessInfo: product.businessInfo ?? "",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      console.error(err);
      toast.error(t("Failed to update business info"));
    }
  };

  useEffect(() => {
    if (systemPrompt?.subcat) {
      setSubcat(systemPrompt.subcat);
    }
  }, [systemPrompt]);

  useEffect(() => {
    if (prompts.length > 0) {
      // check if systemPrompt is null
      if (!systemPrompt) {
        // get 一般產品 prompt
        const generalPrompt = prompts.find(
          (item) => item.code === "ig_product"
        );
        setSystemPrompt(generalPrompt);
      }
    }
  }, [prompts]);

  useEffect(() => {
    // scroll to ref
    if (scrollToRef.current) {
      // scroll to top of parent div
      scrollToSelectedTemplate();
    }
  }, [subcat, systemPrompt]);

  useEffect(() => {
    if (Object.keys(product).length === 0) return;
    updateBusinessInfo();
  }, [debouncedValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!product) return;
    if (!valid) return;
    if (!systemPrompt?.id) {
      toast.error(t("Please select a writing template"));
      return;
    }
    runGPT();
  };

  // Compact view for the main page
  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-bold dark:text-white whitespace-nowrap">{t("Content Generator")}</h2>
          {/* <button
            type="button"
            onClick={() => setSettingsModalOpen(true)}
            className="text-sm font-medium bg-purple-100 text-purple-800 px-3 py-1 rounded-full"
          >
            {t("Advanced Settings")}
          </button> */}
        </div>

        {/* Template Selection - Compact View */}
        <div className="flex items-center space-x-2">
          <div className="text-sm font-medium dark:text-white">
            {t("Template")}:
          </div>
          <div className="flex-grow">
            <select
              className="w-full px-3 py-2 border border-neutral-300 rounded-lg dark:bg-neutral-700 dark:border-neutral-600 dark:text-white"
              value={systemPrompt?.code || ""}
              onChange={(e) => {
                const selected = prompts.find(p => p.code === e.target.value);
                setSystemPrompt(selected);
              }}
            >
              <option value="">{t("Select a template")}</option>
              {prompts.map((item) => (
                <option key={item.code} value={item.code}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Model Selection - Changed to dropdown */}
        <div className="flex items-center space-x-2">
          <div className="text-sm font-medium dark:text-white">
            {t("Model")}:
          </div>
          <div className="flex-grow">
            <select
              className="w-full px-3 py-2 border border-neutral-300 rounded-lg dark:bg-neutral-700 dark:border-neutral-600 dark:text-white"
              value={product?.model || ""}
              onChange={(e) => {
                if (e.target.value === "genie" && !user.isPremium) {
                  setPremiumLockModalOpen(true);
                  return;
                }
                setGPTModel(e.target.value);
              }}
            >
              <option value="">{t("Default")}</option>
              <option value="genie">
                {t("Genie AI")} {!user.isPremium && `(${t("Premium")})`}
              </option>
            </select>
          </div>
        </div>

        {/* Product Name - With internal counter */}
        <div className="flex items-start space-x-2">
          <div className="text-sm font-medium dark:text-white">
            {t("Prompt")}:
          </div>
          <div className="flex-grow relative">
            <textarea
              rows="4"
              className="w-full px-3 py-2 pr-16 border border-neutral-300 rounded-lg dark:bg-neutral-700 dark:border-neutral-600 dark:text-white"
              value={product?.name || ""}
              onChange={(e) => {
                setProduct({
                  ...product,
                  name: e.target.value,
                });
              }}
              placeholder={t("Enter product name")}
            ></textarea>
            <div className="absolute right-3 top-1/2 -translate-y-1/2 text-xs font-medium text-neutral-500">
              {product?.name?.length || 0}/{limit.name}
            </div>
          </div>
        </div>



        {/* Generate Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isAILoading || !valid || !systemPrompt?.id}
            className={`px-6 py-2 rounded-lg font-medium ${isAILoading || !valid || !systemPrompt?.id
              ? "bg-neutral-300 text-neutral-500 cursor-not-allowed"
              : "bg-purple-600 text-white hover:bg-purple-700"
              }`}
          >
            {isAILoading ? (
              <div className="flex items-center space-x-2">
                <LoadingIcon className="animate-spin w-5 h-5 mr-2" />
                {t("Generating")}
              </div>
            ) : (
              t("Generate")
            )}
          </button>
        </div>
      </form>

      {/* Settings Modal */}
      <Transition.Root show={settingsModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => setSettingsModalOpen(false)}
        >
          <div className="flex min-h-screen items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform overflow-hidden rounded-lg bg-white dark:bg-neutral-800 px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle">
                <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-gray-900 dark:text-white">
                  {t("Advanced Settings")}
                </Dialog.Title>

                <div className="mt-4 space-y-6">
                  {/* Template Selection - Full View */}
                  <div>
                    <div className="mb-2 flex items-center justify-between">
                      <div className="text-lg dark:text-white font-bold mb-2">
                        {t("Writing Template")}
                      </div>
                      {systemPrompt?.title && (
                        <div className="mb-2 inline-flex items-center px-2.5 py-0.5 rounded-md font-medium bg-sky-100 text-sky-800">
                          {t("Selected")}: {systemPrompt.title}
                        </div>
                      )}
                    </div>

                    <div className="flex-wrap space-y-2 mb-2">
                      {/* Remove category buttons section */}
                    </div>

                    <div className="flex flex-col items-center gap-2 mb-2 pb-2 max-h-72 overflow-scroll">
                      {getSubcatItems().map((item, idx) => (
                        <button
                          key={idx}
                          type="button"
                          onClick={() => {
                            setSystemPrompt(item);
                          }}
                          className={`flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium ${item.code === systemPrompt?.code
                            ? "bg-purple-500 text-white"
                            : "bg-white"
                            } px-8 py-1 rounded-full shadow`}
                          ref={item.code === systemPrompt?.code ? scrollToRef : null}
                        >
                          <span className="font-bold">{item.title}</span>{" "}
                          <span className="hidden sm:block">({item.description})</span>
                        </button>
                      ))}
                    </div>
                  </div>

                  {/* Extra Prompt */}
                  <div>
                    <div className="flex items-center justify-between">
                      <div className="text-lg dark:text-white font-bold mb-2">
                        {t("Extra Prompt")}
                        <button
                          type="button"
                          onClick={() => {
                            if (user.isPremium) return;
                            setPremiumLockModalOpen(true);
                          }}
                          className="ml-2 text-sm inline-flex items-center px-2.5 py-0.5 rounded-md font-medium bg-purple-100 text-purple-800"
                        >
                          {t("Premium")}
                        </button>
                      </div>
                      <div
                        className={`text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow ${product?.extraPrompt?.length > limit.extraPrompt &&
                          "bg-red-100 text-red-600"
                          }`}
                      >
                        {product?.extraPrompt?.length || 0}/{limit.extraPrompt}
                      </div>
                    </div>

                    <div className="sm:flex w-full">
                      <textarea
                        type="text"
                        className={`w-full px-5 py-2 border border-neutral-300 shadow-sm placeholder-neutral-400 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white rounded-lg resize-none  ${!user.isPremium ? "text-neutral-400 cursor-not-allowed" : ""
                          }`}
                        disabled={!user.isPremium}
                        value={product?.extraPrompt || ""}
                        rows={4}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            extraPrompt: e.target.value,
                          });
                        }}
                        placeholder={`e.g. 寫150字，用新假期語氣，用一個日常生活故事包裝文案`}
                      />
                    </div>
                  </div>

                  <div>
                    {/* My Business Info */}
                    <div className="flex items-center justify-between">
                      <div className="text-lg dark:text-white font-bold mb-2">
                        {t("My Business Info")}
                      </div>

                      <div className="flex items-center space-x-1">
                        {/* Load Default Button */}
                        <button
                          type="button"
                          onClick={() => {
                            loadDefaultBusinessInfo();
                          }}
                          className={`text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow`}
                        >
                          {t("Use Default Value")}
                        </button>

                        {/* Word counter */}
                        <div
                          className={`text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow ${product?.businessInfo?.length > limit.businessInfo &&
                            "bg-red-100 text-red-600"
                            }`}
                        >
                          {product?.businessInfo?.length || 0}/{limit.businessInfo}
                        </div>
                      </div>
                    </div>

                    <div className="sm:flex w-full">
                      <textarea
                        type="text"
                        className={`w-full px-5 py-2 border border-neutral-300 shadow-sm placeholder-neutral-400 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white rounded-lg resize-none`}
                        value={product?.businessInfo || ""}
                        rows={8}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            businessInfo: e.target.value,
                          });
                        }}
                        placeholder="e.g. 商戶名稱、Instagram、簡介etc"
                      />
                    </div>
                  </div>


                  {/* Additional Information */}
                  <div>
                    <div className="flex items-center justify-between">
                      <div className="text-lg dark:text-white font-bold mb-2">
                        {t("Additional Information")}
                        <button
                          type="button"
                          onClick={() => {
                            if (user.isPremium) return;
                            setPremiumLockModalOpen(true);
                          }}
                          className="ml-2 text-sm inline-flex items-center px-2.5 py-0.5 rounded-md font-medium bg-purple-100 text-purple-800"
                        >
                          {t("Premium")}
                        </button>
                      </div>
                      <div
                        className={`text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow ${product?.description?.length > limit.description &&
                          "bg-red-100 text-red-600"
                          }`}
                      >
                        {product?.description?.length || 0}/{limit.description}
                      </div>
                    </div>
                    <div className="mb-2">
                      <span className="text-sm font-bold">
                        {t("Tips")}: {" "}
                        {t("1-2 sentences to express the flow of your copywriting")}
                      </span>
                    </div>
                    <div className="sm:flex w-full">
                      <TextareaAutosize
                        type="text"
                        className={`w-full px-5 py-2 border border-neutral-300 shadow-sm placeholder-neutral-400 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white rounded-lg resize-none ${!user.isPremium ? "text-neutral-400 cursor-not-allowed" : ""
                          }`}
                        value={product?.description || ""}
                        minRows={4}
                        maxRows={8}
                        disabled={!user.isPremium}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            description: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  {/* Brand Tone */}
                  <div>
                    <div className="mb-2 flex items-center justify-between">
                      <div className="text-lg dark:text-white font-bold mb-2">
                        {t("Brand Tone")}
                        <button
                          type="button"
                          onClick={() => {
                            if (user.isPremium) return;
                            setPremiumLockModalOpen(true);
                          }}
                          className="ml-2 text-sm inline-flex items-center px-2.5 py-0.5 rounded-md font-medium bg-purple-100 text-purple-800"
                        >
                          {t("Premium")}
                        </button>
                      </div>
                      <div
                        className={`text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow ${product?.brandTone?.length > limit.brandTone &&
                          "bg-red-100 text-red-600"
                          }`}
                      >
                        {product?.brandTone?.length || 0}/{limit.brandTone}
                      </div>
                    </div>

                    <div className="sm:flex w-full">
                      <TextareaAutosize
                        type="text"
                        className={`w-full px-5 py-2 border border-neutral-300 shadow-sm placeholder-neutral-400 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white rounded-lg resize-none ${!user.isPremium ? "text-neutral-400 cursor-not-allowed" : ""
                          }`}
                        value={product?.brandTone || ""}
                        minRows={4}
                        maxRows={8}
                        disabled={!user.isPremium}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            brandTone: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex justify-end">
                  <button
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500"
                    onClick={() => setSettingsModalOpen(false)}
                  >
                    {t("Save")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );


}
