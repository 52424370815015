import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Transition from "../utils/Transition";
import { useLocalStorage } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

function DropdownProfile(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { t, i18n } = useTranslation();
  const location = useLocation();

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // Dark Mode
  const [theme, setTheme] = useLocalStorage({
    key: "theme",
    defaultValue: "",
  });

  const setThemeMode = (mode) => {
    setTheme(mode);

    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
      localStorage.theme.includes("dark") ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const renderPlan = (isPremium) => {
    if (isPremium) {
      return (
        <div className="text-xs font-bold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
          {t("Premium")}
        </div>
      );
    } else {
      return (
        <div className="text-xs font-bold bg-clip-text text-transparent bg-gradient-to-r from-teal-500 to-sky-500">
          {t("Free")}
        </div>
      );
    }
  };

  const renderUserIcon = (user) => {
    let initial = "U";
    if (user?.firstName) {
      initial = user?.firstName[0] || "U";
    }
    initial = initial.toUpperCase();

    if (user?.profilePic) {
      return (
        <img
          className="w-12 h-12 rounded-full object-cover"
          src={props.user.profilePic}
          alt="User"
        />
      );
    } else {
      return (
        <>
          <div className="flex justify-center items-center w-12 h-12 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
            <span className="text-2xl font-bold text-white">{initial}</span>
          </div>
        </>
      );
    }
  };

  if (!props.user) {
    return (
      <Link
        to="/signin"
        state={{ from: location }}
        className="inline-flex gap-x-2 py-2 px-4 items-center w-full rounded-md text-sm font-medium text-black hover:bg-neutral-200 dark:text-white dark:hover:bg-neutral-800"
      >
        {t("Sign In")}
      </Link>
    );
  } else {
    return (
      <div className="relative inline-flex items-center gap-x-2">
        <button
          ref={trigger}
          className="w-12 inline-flex justify-center items-center group"
          aria-haspopup="true"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          aria-expanded={dropdownOpen}
        >
          {renderUserIcon(props?.user)}
        </button>

        <Transition
          className={`z-20 origin-top-right absolute top-full bg-white dark:bg-neutral-900 dark:border-neutral-600 dark:text-white rounded-lg shadow-lg ${props.align === "right" ? "right-0" : "left-0"
            }`}
          show={dropdownOpen}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div
            ref={dropdown}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
            className="w-72"
          >
            <div className="py-3 px-3 border-b border-neutral-200">
              <Link to="/settings" className="flex items-center gap-x-4">
                {renderUserIcon(props?.user)}
                <div className="flex flex-col w-fit">
                  <div className="font-bold text-lg text-neutral-800 dark:text-white truncate">
                    {props?.user?.firstName}
                  </div>
                  <div className="font-bold text-sm text-neutral-800 dark:text-white truncate">
                    {props?.user?.email}
                  </div>
                  {renderPlan(props?.user?.isPremium)}
                </div>
              </Link>
            </div>
            <ul>
              <li>
                <button
                  className="w-full text-left font-medium flex items-center py-2 px-4 hover:bg-black/10"
                  onClick={() => setThemeMode(theme === "dark" ? "light" : "dark")}
                >
                  {theme === "dark" ? (
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                      {t("Light Mode")}
                    </>
                  ) : (
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                      </svg>
                      {t("Dark Mode")}
                    </>
                  )}
                </button>
              </li>
              <li>
                <button
                  className="w-full text-left font-medium flex items-center py-2 px-4 hover:bg-black/10"
                  onClick={() => i18n.changeLanguage(i18n.language === "en" ? "zh" : "en")}
                >
                  {i18n.language === "en" ? (
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129" />
                      </svg>
                      {t("繁體中文")}
                    </>
                  ) : (
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129" />
                      </svg>
                      {t("English")}
                    </>
                  )}
                </button>
              </li>

              <li>
                <Link
                  className="font-medium flex items-center py-2 px-4 hover:bg-black/10"
                  to="/settings"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  {t("Settings")}
                </Link>
              </li>
              <li>
                <Link
                  className="font-medium flex items-center py-2 px-4 hover:bg-black/10 rounded-b-lg"
                  to="/signout"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                  </svg>
                  {t("Sign Out")}
                </Link>
              </li>
            </ul>
          </div>
        </Transition>
      </div>
    );
  }
}

export default DropdownProfile;
