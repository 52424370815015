import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../images/logo/Logo.svg";
// the hook
import { useTranslation } from "react-i18next";

function Sidebar({ sidebarOpen, setSidebarOpen, user }) {
  const { t } = useTranslation();

  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      // setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div className="fixed sm:static z-40">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`inset-0 bg-neutral-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        aria-hidden="true"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-neutral-900 p-4 transition-all duration-200 ease-in-out ${sidebarOpen ? "translate-x-0" : "-translate-x-64"
          }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-neutral-500 hover:text-neutral-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">{t("Close sidebar")}</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink
            end
            to="/docs"
            className="block"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <Logo className="h-8" />
          </NavLink>
        </div>

        {/* Links */}
        <div className="h-full flex justify-between flex-col">
          {/* Pages group */}
          <div>
            <ul className="mt-3">
              {/* My Writing (Home) */}
              <li
                className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${pathname.includes("/docs") && "bg-white/10"
                  }`}
              >
                <NavLink
                  end
                  to="/docs"
                  className={`px-3 py-2 block group text-neutral-200 hover:text-white truncate transition duration-150 ${pathname.includes("/docs") && "hover:text-neutral-200"
                    }`}
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className={`${pathname.includes("/docs") && "text-sky-500"
                            }`}
                          d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                        />
                      </svg>

                      <span
                        className={`${pathname.includes("/docs") && "text-sky-500"
                          } text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200`}
                      >
                        {t("My Copywriting")}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>

              {/* My Eshop */}
              {/* <li
                className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${pathname.includes("/eshops") && "bg-white/10"
                  }`}
              >
                <NavLink
                  end
                  to="/eshops"
                  className={`px-3 py-2 block group text-neutral-200 hover:text-white truncate transition duration-150 ${pathname.includes("/eshops") && "hover:text-neutral-200"
                    }`}
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className={`${pathname.includes("/eshops") && "text-sky-500"
                            }`}
                          d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                        />
                      </svg>

                      <span
                        className={`${pathname.includes("/eshops") && "text-sky-500"
                          } text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200`}
                      >
                        {t("My E-Shops")}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li> */}

              {/* Chat */}
              {/* <li
                className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${
                  pathname.includes("/chat") && "bg-white/10"
                }`}
              >
                <NavLink
                  end
                  to="/chat"
                  className={`px-3 py-2 block group text-neutral-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes("/chat") && "hover:text-neutral-200"
                  }`}
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className={`${
                            pathname.includes("chat") && "stroke-sky-500"
                          }`}
                          d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                        />
                      </svg>

                      <span
                        className={`
                      ${pathname.includes("/chat") && "text-sky-500"}
                      text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200`}
                      >
                        {t("AI Chat")}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li> */}

              {/* AI Image */}
              {/* <li
                className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${
                  pathname.includes("/image") && "bg-white/10"
                }`}
              >
                <NavLink
                  end
                  to="/image"
                  className={`px-3 py-2 block group text-neutral-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes("/image") && "hover:text-neutral-200"
                  }`}
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className={`${
                            pathname.includes("image") && "stroke-sky-500"
                          }`}
                          d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                        />
                      </svg>

                      <span
                        className={`
                      ${pathname.includes("/image") && "text-sky-500"}
                      text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200`}
                      >
                        {t("AI Image")}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li> */}

              {/* Premium */}
              <li
                className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${pathname.includes("/settings#plans") && "bg-white/10"
                  }`}
              >
                <NavLink
                  end
                  to="/settings#plans"
                  className={`px-3 py-2 block group text-neutral-200 hover:text-white truncate transition duration-150 ${pathname.includes("/settings#plans") &&
                    "hover:text-neutral-200"
                    }`}
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          className={`${pathname.includes("/settings#plans") &&
                            "stroke-sky-500"
                            }`}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                        />
                      </svg>

                      <span
                        className={`
                      ${pathname.includes("/settings#plans") && "text-sky-500"}
                      text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200`}
                      >
                        {t("Plan")}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            </ul>
            {user && user?.role === "admin" && (
              <>
                <h3 className="mt-3 text-xs uppercase text-neutral-500 font-semibold pl-3">
                  <span className="hidden lg:block">•••</span>
                </h3>
                <ul className="">
                  {/* All Users */}
                  <li
                    className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${pathname.includes("/allusers") && "bg-white/10"
                      }`}
                  >
                    <NavLink
                      end
                      to="/allusers"
                      className={`px-3 py-2 block group text-neutral-200 hover:text-white truncate transition duration-150 ${pathname.includes("/allusers") &&
                        "hover:text-neutral-200"
                        }`}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-6 h-6 group-hover:scale-110"
                          >
                            <path
                              className={`${pathname.includes("/allusers") && "text-sky-500"
                                }`}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                            />
                          </svg>

                          <span
                            className={`
                          ${pathname.includes("/allusers") && "text-sky-500"}
                          text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200`}
                          >
                            {t("All Users")}
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  {/* Coupon */}
                  <li
                    className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${pathname.includes("/allcoupons") && "bg-white/10"
                      }`}
                  >
                    <NavLink
                      end
                      to="/allcoupons"
                      className={`px-3 py-2 block group text-neutral-200 hover:text-white truncate transition duration-150 ${pathname.includes("/allcoupons") &&
                        "hover:text-neutral-200"
                        }`}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 group-hover:scale-110"
                          >
                            <path
                              className={`${pathname.includes("/allcoupons") &&
                                "text-sky-500"
                                }`}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
                            />
                          </svg>

                          <span
                            className={`${pathname.includes("/allcoupons") && "text-sky-500"
                              } text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200`}
                          >
                            {t("All Coupons")}
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </>
            )}
          </div>

          {/* More group */}
          <div>
            <h3 className="text-xs uppercase text-neutral-500 font-semibold pl-3">
              <span
                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                aria-hidden="true"
              >
                •••
              </span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                {t("Others")}
              </span>
            </h3>
            <ul className="mt-3">
              {/* Trash */}
              <li
                className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${pathname.includes("/trash") && "bg-white/10"
                  }`}
              >
                <NavLink
                  end
                  to="/trash"
                  className={`px-3 py-2 block group text-neutral-200 hover:text-white truncate transition duration-150 ${pathname.includes("/trash") && "hover:text-neutral-200"
                    }`}
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          className={`${pathname.includes("trash") && "stroke-sky-500"
                            }`}
                        />
                      </svg>

                      <span
                        className={`${pathname.includes("/trash") && "text-sky-500"
                          } text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200`}
                      >
                        {t("Trash")}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>

              {/* Settings */}
              <li
                className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${pathname.includes("/settings") && "bg-white/10"
                  }`}
              >
                <NavLink
                  end
                  to="/settings"
                  className={`px-3 py-2 block group text-neutral-200 hover:text-white truncate transition duration-150 ${pathname.includes("/settings") && "hover:text-neutral-200"
                    }`}
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          className={`${pathname.includes("settings") && "stroke-sky-500"
                            }`}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                        />
                      </svg>

                      <span
                        className={`${pathname.includes("/settings") && "text-sky-500"
                          } text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200`}
                      >
                        {t("Settings")}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button
              onClick={() => {
                setSidebarExpanded(!sidebarExpanded);
              }}
            >
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-neutral-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-neutral-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
