import { useFormik } from "formik";
import * as Yup from "yup";

import { Fragment, useState } from "react";
import { Dialog, Transition, Disclosure } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import { toast } from "react-toastify";
import ModelSelection from "../prompt/ModelSelection";
import { useLocalStorage } from "@mantine/hooks";

export default function EditSystemPromptModal(props) {
  // i18n
  const { t } = useTranslation();

  const axiosPrivate = useAxiosPrivate();

  const [systemPrompt, setSystemPrompt] = useLocalStorage({
    key: "systemPrompt",
    defaultValue: props.prompts[0],
  });

  // Get Prompts
  const updatePrompt = async (values) => {
    try {
      await axiosPrivate.patch(`${endpoints.PROMPTS_URL}/${values.id}`, values);
      toast.success(t("System Prompt updated successfully"));
      props.setOpen(false);

      // Update the prompts
      props.getPrompts();
    } catch (err) {
      console.log(err);
    }
  };

  const deletePrompt = async (id) => {
    try {
      await axiosPrivate.delete(`${endpoints.PROMPTS_URL}/${id}`);
      toast.success(t("System Prompt updated successfully"));
      props.setOpen(false);

      // Update the prompts
      props.getPrompts();

      // update systemPrompt
      if (systemPrompt.id === id) {
        setSystemPrompt(props.prompts[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: systemPrompt?.id || "",
      title: systemPrompt?.title || "",
      prompt: systemPrompt?.prompt || "",
      temperature: systemPrompt?.temperature || 0.7,
      shortcut: systemPrompt?.shortcut || "",
      cat: systemPrompt?.cat || "",
      example: systemPrompt?.example || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("This field is required"),
      shortcut: Yup.string().optional(),
      prompt: Yup.string().required("This field is required"),
      temperature: Yup.number().required("This field is required"),
      cat: Yup.string().optional(),
      example: Yup.string().optional(),
    }),
    enableReinitialize: true,
    onSubmit: updatePrompt,
  });

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={props.setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              onSubmit={formik.handleSubmit}
              className="inline-block bg-white dark:bg-neutral-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-3xl w-full p-6"
            >
              <div>
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center space-x-2">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-black dark:text-white"
                    >
                      {t("Edit System Prompt")}
                    </Dialog.Title>
                  </div>
                  <button
                    type="button"
                    onClick={() => props.setOpen(false)}
                    className="hover:scale-105 transition-all"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div>
                <div className="mt-4">
                  <div className="flex items-center">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-neutral-700"
                    >
                      {t("Title")}
                    </label>
                    <span className="text-rose-500">*</span>
                  </div>
                  <input
                    className="mt-2 shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-neutral-300 rounded-md resize-none"
                    type="text"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    autoComplete="off"
                  />
                  <p className="text-red-600 text-sm">
                    {formik.errors.title && formik.touched.title
                      ? `${t("Error")}: ${formik.errors.title}`
                      : null}
                  </p>
                </div>
                <div className="mt-2">
                  <div className="flex items-center">
                    <label
                      htmlFor="prompt"
                      className="block text-sm font-medium text-neutral-700"
                    >
                      {t("System Prompt")}
                    </label>
                    <span className="text-rose-500">*</span>
                  </div>
                  <TextareaAutosize
                    className="mt-2 shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-neutral-300 rounded-md resize-none"
                    type="text"
                    name="prompt"
                    maxRows={16}
                    minRows={8}
                    rows={8}
                    placeholder={`${t("Enter your prompt")}...`}
                    value={formik.values.prompt}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    autoFocus
                  />
                  <p className="text-red-600 text-sm">
                    {formik.errors.prompt && formik.touched.prompt
                      ? `${t("Error")}: ${formik.errors.prompt}`
                      : null}
                  </p>
                </div>
                <div className="mt-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="temperature"
                      className="block text-sm font-medium text-neutral-700"
                    >
                      {t("Temperature")}
                    </label>
                    <span className="text-sm text-neutral-500" id="temperature">
                      {formik.values.temperature}
                    </span>
                  </div>
                  <input
                    name="temperature"
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    value={formik.values.temperature}
                    onChange={formik.handleChange}
                    className="mt-2 w-full h-2 mb-6 bg-neutral-200 rounded-lg appearance-none cursor-pointer dark:bg-neutral-700"
                  />
                </div>
                <div className="mt-2">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-sky-100 px-4 py-2 text-left text-sm font-medium text-sky-900 hover:bg-sky-200 focus:outline-none focus-visible:ring focus-visible:ring-sky-500 focus-visible:ring-opacity-75">
                          <span>{t("Advanced Settings")}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-sky-500`}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.5 15.75l7.5-7.5 7.5 7.5"
                            />
                          </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className="pt-4 pb-2 text-sm text-neutral-500">
                          <div className="mt-4">
                            <div className="flex justify-between items-center">
                              <label
                                htmlFor="shortcut"
                                className="block text-sm font-medium text-neutral-700"
                              >
                                {t("Shortcut")}
                              </label>
                            </div>
                            <input
                              className="mt-2 shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-neutral-300 rounded-md resize-none"
                              type="text"
                              name="shortcut"
                              placeholder={t(
                                "Use '/{shortcut}' to trigger this prompt"
                              )}
                              value={formik.values.shortcut}
                              onChange={formik.handleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="mt-4">
                            <div className="flex justify-between items-center">
                              <label
                                htmlFor="cat"
                                className="block text-sm font-medium text-neutral-700"
                              >
                                {t("Category")}
                              </label>
                            </div>
                            <input
                              className="mt-2 shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-neutral-300 rounded-md resize-none"
                              type="text"
                              name="cat"
                              placeholder={t(
                                `Categorize this prompt by a name`
                              )}
                              value={formik.values.cat}
                              onChange={formik.handleChange}
                              autoComplete="off"
                            />
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
                {/* Submit button */}
                <div className="mt-4 flex justify-between space-x-2">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      deletePrompt(formik.values.id);
                    }}
                    className="w-full flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                  >
                    {t("Delete")}
                  </button>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                  >
                    {t("Save")}
                  </button>
                </div>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
