import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

export default ({ open, setOpen, prompts, systemPrompt, setSystemPrompt }) => {
  const { t } = useTranslation();
  const [subcat, setSubcat] = useState("");

  // Sort prompts by subcat
  prompts = prompts.sort((a, b) => a.subcat.localeCompare(b.subcat));

  const getSubcatItems = () => prompts.filter(item => item.subcat === subcat);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center py-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="z-10 transform shadow-lg w-full sm:max-w-4xl md:max-w-5xl rounded-lg bg-white overflow-auto sm:min-h-[60vh]">
              <div className="mb-2 flex items-center justify-between">
                <div className="text-lg dark:text-white font-bold mb-2">
                  {t("Writing Template")}
                </div>

                {systemPrompt?.title && (
                  <div className="mb-2 inline-flex items-center px-2.5 py-0.5 rounded-md font-medium bg-sky-100 text-sky-800">
                    {t("Selected")}: {systemPrompt.title}
                  </div>
                )}
              </div>

              <div className="flex-wrap space-y-2 mb-2">
                {[...new Set(prompts.map(item => item.subcat))].map((cat, idx) => (
                  <button
                    key={idx}
                    type="button"
                    onClick={() => setSubcat(cat)}
                    className={`text-sm font-medium transition-all mr-2 ${cat === subcat ? "bg-purple-500 text-white" : "bg-white"
                      } px-8 py-1 rounded-full shadow`}
                  >
                    {t(cat)}
                  </button>
                ))}
              </div>

              <div className="flex flex-col items-center gap-2 mb-2 pb-2 max-h-48 overflow-scroll">
                {getSubcatItems().map((item, idx) => (
                  <button
                    key={idx}
                    type="button"
                    onClick={() => setSystemPrompt(item)}
                    className={`flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium ${item.code === systemPrompt?.code
                        ? "bg-purple-500 text-white"
                        : "bg-white"
                      } px-8 py-1 rounded-full shadow`}
                  >
                    <span className="font-bold">{item.title}</span>
                    <span>({item.description})</span>
                  </button>
                ))}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
