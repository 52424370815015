import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useDebouncedValue } from "@mantine/hooks";
import wordsCount from "words-count";
import { useBeforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import Header from "../../partials/Header";

import EditorTutorialModal from "../../partials/modals/EditorTutorialModal";
import PremiumLockModal from "../../partials/modals/PremiumLockModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import PageNotFound from "../error/PageNotFound";
import { toast } from "react-toastify";
import { addSuffixToText } from "../../utils/Utils";

import { ReactComponent as LoadingIcon } from "../../images/Loader.svg";
import FormPanel from "./partials/FormPanel";
import EditorPanel from "./partials/EditorPanel";

function Editor() {
  const { t } = useTranslation();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  // ID from url
  const { id } = useParams();
  const [isInited, setIsInited] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isAILoading, setAILoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [premiumLockModalOpen, setPremiumLockModalOpen] = useState(false);
  const [tutorialModalOpen, setTutorialModalOpen] = useState(false);
  const limit = {
    name: 500,
  };

  // Error fallback
  const [tempWriting, setTempWriting] = useState("");

  const [product, setProduct] = useState({});
  const [isNotFound, setNotFound] = useState(false);
  const [writingObj, setWritingObj] = useState();
  const [writing, setWriting] = useState("");
  const [title, setTitle] = useState("");
  const [writingDebounce] = useDebouncedValue(writing, 1000, { leading: true });
  const [productDebounce] = useDebouncedValue(product, 1000, { leading: true });
  const [systemPrompt, setSystemPrompt] = useState();
  const [prompts, setPrompts] = useState([]);
  const [showSaving, setShowSaving] = useState(false);
  const [valid, setValid] = useState(true);

  // Get User Profile
  const { auth } = useAuth();
  const user = auth.user;

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();
  const runGPTController = new AbortController();

  // Word Count
  const [wordsCounter, setWordsCounter] = useState(0);

  // Credit
  const [quota, setQuota] = useState();

  // Fetch businessInfo
  const fetchBusinessInfo = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.PROFILE_URL);
      return res.data.businessInfo;
    } catch (err) {
      console.error(err);
      toast.error(t("Failed to get business info"));
    }
  };

  // Fetch Writing
  const fetchWriting = async () => {
    try {
      if (id) {
        const res = await axiosPrivate.get(endpoints.WRITINGS_URL + "/" + id, {
          signal: controller.signal,
        });

        setWritingObj(res.data);

        // Update title
        setTitle(res.data?.title);

        // Add business info to product if res.data.product?.businessInfo is empty
        if (res.data?.product) {
          if (!res.data.product?.businessInfo) {
            res.data.product.businessInfo = await fetchBusinessInfo();
          }
        } else {
          res.data.product = {
            businessInfo: await fetchBusinessInfo(),
          };
        }

        // Update product
        setProduct(res.data?.product || {});

        // Fallback to content
        setWriting(res.data?.content || "");

        return res.data;
      } else {
        // Cannot Found
        setNotFound(true);
      }
    } catch (error) {
      console.error(error);
      toast.error(t("Something went wrong, please try again later"));
    }
  };

  // Fetch Writing
  const fetchQuota = async () => {
    try {
      if (id) {
        const res = await axiosPrivate.get(endpoints.PROFILE_URL + "/quota", {
          signal: controller.signal,
        });

        // Update title
        setQuota(res.data);

        return res.data;
      } else {
        // Cannot Found
        setNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Update Writing
  const updateWriting = async () => {
    try {
      if (id) {
        // Use the first line as title
        const title = writing.split("\n")[0];

        setTitle(title);

        // Get Content
        const content = writing;

        const payload = { title, content, product };

        if (systemPrompt?.code) {
          payload.promptId = systemPrompt.code;
        }

        // Update to DB
        await axiosPrivate.patch(endpoints.WRITINGS_URL + "/" + id, payload, {
          signal: controller.signal,
        });
        console.log("Updated to DB...");
      } else {
        // Cannot Found
        setNotFound(true);
      }
    } catch (error) {
      console.error(error);
      // setNotFound(true);
    } finally {
      setIsSaving(false);

      // setTimtout to show saving
      setShowSaving(true);

      setTimeout(() => {
        setShowSaving(false);
      }, 2000);
    }
  };

  const runGPT = async () => {
    try {
      if (isAILoading) return;

      // fetch quota
      const quota = await fetchQuota();

      if (!user.isPremium && quota?.hasQuota === false) {
        setPremiumLockModalOpen(true);
        return;
      } else {
        // Pretend reducing quota
        setQuota({
          ...quota,
          remainingQuota: quota.remainingQuota - 1,
        });
      }

      setAILoading(true);

      // Store current writing to temp
      setTempWriting(writing);

      setWriting("");

      let finalStream = "";

      let hasConsumedQuota = false;

      let productToSubmit = product;

      // if user is not premium, keep only product name and type
      if (!user.isPremium) {
        const { name } = product;

        productToSubmit = { name };
      }

      // DEBUG: Remove product type
      delete productToSubmit.type;

      await axiosPrivate.post(
        endpoints.AI_COPYWRITER_URL,
        {
          docId: id,
          promptId: systemPrompt.code,
          product: productToSubmit,
        },
        {
          signal: runGPTController.signal,
          withCredentials: false,
          onDownloadProgress: (progressEvent) => {
            if (!hasConsumedQuota) {
            }

            finalStream = "";

            const dataChunk = progressEvent.target.response;

            let concatedPayload = dataChunk.split("data: ");

            // Filter out empty strings
            concatedPayload = concatedPayload.filter(
              (str) => str.trim() !== ""
            );

            for (const line of concatedPayload) {
              const jsonString = line;

              try {
                if (jsonString.trim() !== "[DONE]") {
                  const dataObj = JSON.parse(jsonString);

                  if (dataObj.choices && dataObj.choices.length > 0) {
                    if (dataObj?.choices && dataObj.choices.length > 0) {
                      const delta = dataObj.choices[0].delta.content;

                      if (delta) {
                        finalStream += delta;

                        if (finalStream !== "") {
                          setWriting(finalStream.trim());
                        }
                      }
                    }
                  } else {
                    console.log("triggered prompt filter results");

                    if (dataObj?.error) {
                      toast.error(dataObj.error);
                    }
                  }
                }
              } catch (error) {
                if (jsonString.trim()) {
                  console.log("err dataChunk: ", jsonString);
                  const dataChunkObj = JSON.parse(dataChunk);
                  const errCode = dataChunkObj?.error?.code;
                  if (errCode) {
                    toast.error(t(errCode));
                  }

                  if (dataChunkObj?.error) {
                    toast.error(dataChunkObj.error);
                  }
                }

                if (line?.errors) {
                  toast.error(line.errors.message);
                } else {
                  console.log(error);
                }
              }
            }
          },
        }
      );

      console.log("finalStream: ", finalStream);

      // Update generate history

      if (finalStream.trim()) {
        const tempRes = await axiosPrivate.post(
          `${endpoints.WRITINGS_URL}/${id}/genHistory`,
          {
            content: finalStream.trim(),
          }
        );

        // Update writing
        setWritingObj({ ...writingObj, genHistory: tempRes?.data?.genHistory });
      }

      if (!finalStream.trim()) {
        toast.error(t("Something went wrong, please try again later"));

        // Restore writing
        setWriting(tempWriting);

        // set writing
        setTempWriting("");
      }
    } catch (err) {
      console.log("error!");
      toast.error(t("Something went wrong, please try again later"));

      // Restore writing
      setWriting(tempWriting);

      // set writing
      setTempWriting("");

      fetchQuota();
    }

    setAILoading(false);
  };

  const checkValid = () => {
    if (
      product?.name?.length > limit.name ||
      product?.type?.length > limit.type ||
      product?.description?.length > limit.description ||
      product?.brandTone?.length > limit.brandTone ||
      product?.extraPrompt?.length > limit.extraPrompt ||
      product?.businessInfo?.length > limit.businessInfo
    ) {
      setValid(false);
    } else {
      setValid(true);
    }
  };

  const init = async () => {
    try {
      // Get Writing
      const writing = await fetchWriting();
      await fetchQuota();
      const prompts = await getPrompts();

      // Set current system prompt
      const currentSystemPrompt = prompts.find((item) => {
        return item.code === writing.promptId;
      });

      setSystemPrompt(currentSystemPrompt);

      setLoading(false);

      setIsInited(true);
    } catch (error) {
      console.error(error);
    }
  };

  // Update writing after initialization
  useEffect(() => {
    if (isInited) {
      updateWriting();
    }
  }, [writingDebounce, productDebounce, systemPrompt]);

  // main
  useEffect(() => {
    init();
  }, []);

  // Content Listener
  useEffect(() => {
    if (writing || writing === "") {
      // Set Word Counter
      setWordsCounter(wordsCount(writing));
    }
  }, [writing]);

  // Loading Inidicator
  useEffect(() => {
    if (
      (writing !== writingDebounce || product !== productDebounce) &&
      isInited
    ) {
      setIsSaving(true);
    }
  }, [product]);

  useBeforeunload((event) => {
    if (isSaving === true) {
      event.preventDefault();
      return t("Your work is saving. Are you sure you want to leave?");
    }
  });

  const getPrompts = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.PROMPTS_URL);

      const prompts = res.data || [];

      setPrompts(prompts);

      return prompts;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkValid();
  }, [product]);

  if (!isLoading && isNotFound) {
    return <PageNotFound />;
  } else {
    return (
      <div className="flex">
        {/* Content area */}
        <div className="relative flex flex-col flex-1">
          <Header
            user={user}
            title={title}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            customBtn={
              <Link
                to="/docs"
                className="text-neutral-500 hover:text-neutral-600 dark:text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                  />
                </svg>
              </Link>
            }
            otherComponents={
              <>
                {isAILoading && (
                  <div className="text-sm inline-flex space-x-2 items-center font-medium bg-indigo-100 text-indigo-600 rounded-full text-center p-2.5 sm:px-2.5 sm:py-1">
                    <svg
                      className="animate-spin w-3 h-3 fill-current text-indigo-600 shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"></path>
                    </svg>
                    <span className="hidden sm:inline whitespace-nowrap">
                      {t("Generating")}
                    </span>
                  </div>
                )}
                {isSaving ? (
                  <div className="text-sm inline-flex space-x-2 items-center font-medium bg-amber-100 text-amber-600 rounded-full text-center p-2.5 sm:px-2.5 sm:py-1">
                    <svg
                      className="animate-spin w-3 h-3 fill-current text-amber-600 shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"></path>
                    </svg>
                    <span className="hidden sm:inline whitespace-nowrap">
                      {t("Saving")}
                    </span>
                  </div>
                ) : showSaving ? (
                  <div className="text-sm inline-flex space-x-2 items-center font-medium bg-emerald-100 text-emerald-600 rounded-full text-center p-2.5 sm:px-2.5 sm:py-1">
                    <svg
                      className="w-3 h-3 fill-current text-emerald-600 shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14.3 2.3L5 11.6 1.7 8.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"></path>
                    </svg>
                    <span className="hidden sm:inline whitespace-nowrap">
                      {t("Saved")}
                    </span>
                  </div>
                ) : null}
                <button
                  onClick={() => {
                    setTutorialModalOpen(true);
                  }}
                  className="text-sm inline-flex space-x-2 items-center font-medium bg-sky-100 text-sky-600 rounded-full text-center px-2.5 py-1 sm:px-2.5"
                >
                  <span className="inline whitespace-nowrap">
                    {t("Tutorial")}
                  </span>
                </button>
              </>
            }
          />

          <main className="pb-[72px] sm:pb-0">
            <PremiumLockModal
              open={premiumLockModalOpen}
              setOpen={setPremiumLockModalOpen}
            />

            <EditorTutorialModal
              open={tutorialModalOpen}
              setOpen={setTutorialModalOpen}
            />

            <div className="dark:bg-neutral-900 px-4 sm:px-6 lg:px-8">
              <div className="mx-auto sm:max-w-[800px] flex flex-col gap-6 pb-20">
                {/* FormPanel at the top */}
                <div className="w-full bg-white dark:bg-neutral-800 rounded-lg shadow-md p-4">
                  <FormPanel
                    prompts={prompts}
                    product={product}
                    valid={valid}
                    systemPrompt={systemPrompt}
                    setSystemPrompt={setSystemPrompt}
                    setProduct={setProduct}
                    runGPT={runGPT}
                    isAILoading={isAILoading}
                    limit={limit}
                    user={user}
                    setPremiumLockModalOpen={setPremiumLockModalOpen}
                    LoadingIcon={LoadingIcon}
                    isCompact={true}
                  />
                </div>

                {/* EditorPanel at the bottom */}
                <div className="w-full flex-grow">
                  <EditorPanel
                    writing={writing}
                    writingObj={writingObj}
                    setWriting={setWriting}
                    wordsCounter={wordsCounter}
                    user={user}
                    isAILoading={isAILoading}
                    addSuffixToText={addSuffixToText}
                  />
                </div>
              </div>
            </div>

            {/* Add fixed bottom bar for mobile */}
            <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-neutral-800 p-3 sm:hidden border-t border-neutral-200 dark:border-neutral-700 flex items-center justify-between">
              {valid ? (
                <button
                  onClick={runGPT}
                  disabled={isAILoading}
                  className={`flex items-center justify-center px-4 py-1 border border-transparent text-base rounded-full text-white font-bold ${isAILoading ? "bg-black/20" : "bg-black"
                    }`}
                >
                  {t("Generate")}
                  {isAILoading && (
                    <LoadingIcon className="ml-2 w-4 h-4 animate-spin fill-white" />
                  )}
                </button>
              ) : (
                <span className="flex items-center justify-center px-4 py-1 border border-transparent text-base rounded-full text-white font-bold bg-black/20">
                  {t("Generate")}
                </span>
              )}

              <div className="flex items-center space-x-2">
                <div className="text-sm font-medium bg-neutral-100 dark:bg-neutral-700 px-3 py-1 rounded-full">
                  {wordsCounter} {t("words")}
                </div>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(writing);
                    toast.success(t("Copied to clipboard"));
                  }}
                  className="text-sm font-medium bg-neutral-100 dark:bg-neutral-700 px-4 py-1 rounded-full"
                >
                  {t("Copy")}
                </button>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default Editor;
