import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useClipboard } from "@mantine/hooks";

export default function EditorPanel({
  writing,
  writingObj,
  setWriting,
  wordsCounter,
  user,
  isAILoading,
  addSuffixToText,
}) {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const clipboard = useClipboard({ timeout: 1000 });

  // Update current index when writing changes
  useEffect(() => {
    if (writingObj?.genHistory?.length > 0) {
      const index = writingObj.genHistory.indexOf(writing);
      if (index !== -1) {
        setCurrentIndex(index);
      }
    }
  }, [writing, writingObj?.genHistory]);

  const copyText = () => {
    const copyableText = addSuffixToText(writing, user);

    // Copy to clipboard
    clipboard.copy(copyableText);
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="text-lg dark:text-white font-bold mb-2">
          {t("My Copywriting")}
        </div>

        <div
          className={`mb-2 flex items-center justify-between space-x-2 transition-all`}
        >
          {writingObj?.genHistory && writingObj.genHistory.length > 0 && (
            <div className="flex items-center space-x-2">
              <div className="text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow flex items-center">
                {currentIndex + 1}/{writingObj?.genHistory?.length || 0}{" "}
                <span className="hidden sm:block">{t("Version")}</span>
              </div>
              <div className="rounded-full shadow bg-white">
                <button
                  onClick={() => {
                    // Navigate to previous history item
                    const prevIndex = Math.max(currentIndex - 1, 0);
                    if (prevIndex !== currentIndex) {
                      setWriting(writingObj.genHistory[prevIndex]);
                    }
                  }}
                  className={`text-sm group font-medium transition-all px-1 sm:px-3 py-1 ${currentIndex <= 0
                    ? "opacity-40 cursor-not-allowed"
                    : "hover:bg-gray-100 dark:hover:bg-neutral-700"
                    }`}
                  disabled={currentIndex <= 0}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 group-hover:-translate-x-0.5 transition-all"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 19.5 8.25 12l7.5-7.5"
                    />
                  </svg>
                </button>
                <button
                  onClick={() => {
                    // Navigate to next history item
                    const nextIndex = Math.min(currentIndex + 1, writingObj.genHistory.length - 1);
                    if (nextIndex !== currentIndex) {
                      setWriting(writingObj.genHistory[nextIndex]);
                    }
                  }}
                  className={`text-sm group font-medium transition-all px-1 sm:px-3 py-1 ${currentIndex >= writingObj.genHistory.length - 1
                    ? "opacity-40 cursor-not-allowed"
                    : "hover:bg-gray-100 dark:hover:bg-neutral-700"
                    }`}
                  disabled={currentIndex >= writingObj.genHistory.length - 1}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 group-hover:translate-x-0.5 transition-all"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}

          <div className="text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow">
            {wordsCounter} {t("words")}
          </div>

          <button
            type="button"
            onClick={() => {
              copyText();
            }}
            className={`text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow ${clipboard.copied
              ? "text-emerald-500 scale-105"
              : "text-neutral-800"
              }`}
          >
            {clipboard.copied ? (
              `${t("Copied")}!`
            ) : (
              <>
                <span className="sm:hidden">{t("Copy")}</span>
                <span className="hidden sm:block">
                  {t("Copy this writing")}
                </span>
              </>
            )}
          </button>
        </div>
      </div>

      <div className="relative">
        <div
          className="mb-8 w-full h-full bg-neutral-50 dark:bg-neutral-800 rounded-xl relative text-neutral-900 dark:text-white dark:placeholder:text-white p-4 overflow-y-auto"
        >
          {isAILoading && writing === "" ? (
            <div className="text-neutral-500 dark:text-neutral-400">
              {t("Processing")}...
            </div>
          ) : writing ? (
            <div className="whitespace-pre-wrap">{writing}</div>
          ) : (
            <div className="text-neutral-500 dark:text-neutral-400">
              {t("Start by selecting the type of product you want to write about")}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
